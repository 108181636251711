import React from 'react'
import { Box, Heading, Icon } from '@chakra-ui/react'

interface CardProps {
    bgColor: string
    title: string
    icon: any
    data: number
}

const CardTotal: React.FC<CardProps> = (props: CardProps) => {
    const { bgColor, title, icon, data } = props
    return (
        <Box
            p={5}
            bg={'white'}
            color={'gray.500'}
            shadow={'md'}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            borderRadius={20}
        >
            <Box>
                <Heading size={'md'}>{data}</Heading>
                <Heading fontSize={'1.3rem'}>{title}</Heading>
            </Box>
            <Box bg={bgColor} borderRadius={20} padding={3}>
                <Icon as={icon} fontSize={'2rem'} color={'white'} />
            </Box>
        </Box>
    )
}

export default CardTotal
