import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../setupAPI'
//there too

export const posAPI = createApi({
    reducerPath: 'posAPI',
    baseQuery: baseQuery,
    tagTypes: ['Transactions'],
    endpoints: builder => ({
        addTransaction: builder.mutation({
            query: payload => ({
                url: '/transaction',
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['Transactions'],
        }),
        getOneTransaction: builder.query({
            query: ({ id, status }) => `/transaction/${id}?status=${status}`,
        }),
    }),
})

export const { useAddTransactionMutation, useGetOneTransactionQuery } = posAPI
