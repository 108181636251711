import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import Customer from '../../models/Customer'

const initialState: any = {
    fullname: '',
    address: '',
    phone: '',
    email: '',
    customer_options: [],
}

const customerSlice = createSlice({
    name: 'customerSlice',
    initialState,
    reducers: {
        setCustomerData(state, action: PayloadAction<{ name: string; value: string }>) {
            state[action.payload.name] = action.payload.value
        },
        setCustomerState(state, action) {
            state.fullname = action.payload.fullname
            state.address = action.payload.address
            state.phone = action.payload.phone
            state.email = action.payload.email
        },
        setCustomerOptions(state, action) {
            let data = action.payload
            interface Affected {
                id: number
                value: string
                label: string
            }
            let affected: Affected[] = []
            data.forEach((row: Customer, index: number) => {
                affected.push({ id: row.customer_id, value: row.fullname, label: row.fullname })
            })
            return {
                ...state,
                customer_options: affected,
            }
        },
        reset(state, action: PayloadAction<void>) {
            state.fullname = ''
            state.address = ''
            state.phone = ''
            state.email = ''
        },
    },
})

export const { setCustomerData, setCustomerState, setCustomerOptions, reset } =
    customerSlice.actions
export default customerSlice
