import {
    Box,
    Button,
    Heading,
    Img,
    Input,
    InputGroup,
    InputRightElement,
    Text,
} from '@chakra-ui/react'
import { FC } from 'react'
import { Eye } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { useLoginMutation } from '../../features/auth/authAPI'
import { loginAction, setUserData } from '../../features/auth/authSlice'
import { useToast } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import aksaIcon from '../../common/aksa-1.png'
import { RootState } from '../../app/store'
import React from 'react'

const Login: FC = () => {
    const [login, { isLoading }] = useLoginMutation()
    const dispatch = useDispatch()
    const { email, password } = useSelector((state: RootState) => state.authReducer)
    const toast = useToast()
    const navigate = useNavigate()

    const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
        dispatch(loginAction({ name: e.currentTarget.name, value: e.currentTarget.value }))
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const formData = {
            email: email,
            password: password,
        }
        const res: any = await login(formData)
        if (res.data) {
            dispatch(setUserData(res.data.data))
            toast({
                title: 'Login Successfull.',
                status: 'success',
                colorScheme: 'blue',
                duration: 3000,
                isClosable: true,
            })
            navigate('/choose-business')
        } else {
            toast({
                title: res?.error?.data?.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        }
    }

    return (
        <Box
            w={'full'}
            minH={'100vh'}
            background={'gray.100'}
            display={'flex'}
            alignItems='center'
            justifyContent={'center'}
        >
            <Box background={'white'} w={['90%', '60%', '35%']} shadow={'md'} p={5}>
                <Img src={aksaIcon} width={'70px'} m={'0 auto'} />
                <Heading textAlign={'center'} color={'gray.500'} size={'lg'} mt={2}>
                    Aksa
                    <Text display={'inline'} color={'blue.500'}>
                        POS
                    </Text>
                </Heading>
                <Box mt={10}>
                    <form onSubmit={handleSubmit}>
                        <Input
                            type={'text'}
                            my={3}
                            py={6}
                            name={'email'}
                            placeholder={'Email'}
                            bg={'gray.100'}
                            onChange={handleChange}
                        />
                        <InputGroup>
                            <Input
                                type={'password'}
                                my={3}
                                py={6}
                                name={'password'}
                                placeholder={'Password'}
                                bg={'gray.100'}
                                onChange={handleChange}
                            />
                            <InputRightElement
                                width='4rem'
                                justifyContent={'center'}
                                display='flex'
                                h={'100%'}
                            >
                                <Eye />
                            </InputRightElement>
                        </InputGroup>
                        <Button
                            type={'submit'}
                            my={3}
                            py={6}
                            w={'full'}
                            background={'blue.600'}
                            color={'white'}
                            _hover={{
                                background: 'blue.800',
                                fontWeight: 'bold',
                            }}
                            isLoading={isLoading}
                        >
                            Login
                        </Button>
                    </form>
                    <Text my={5} color={'gray.400'} textAlign={'center'}>
                        Forgot your password?
                    </Text>
                    <Button my={3} py={6} w={'full'}>
                        Create an Account
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

export default Login
