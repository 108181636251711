const validateAddSupplier = state => {
    let errMsg = {}
    if (state.supplier_name.trim() === '') {
        errMsg.supplier_name = 'Supplier Name cannot be empty'
    }

    return {
        status: Object.keys(errMsg).length === 0 ? 'success' : 'error',
        errMsg,
    }
}

export { validateAddSupplier }
