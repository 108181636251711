const validateAddProduct = state => {
    let errMsg = {}
    if (state.product_name.trim() === '') {
        errMsg.product_name = 'Product Name cannot be empty'
    }

    return {
        status: Object.keys(errMsg).length === 0 ? 'success' : 'error',
        errMsg,
    }
}

export { validateAddProduct }
