import { createApi } from '@reduxjs/toolkit/query/react'
import Customer from '../../models/Customer'
import { baseQuery } from '../setupAPI'
//there too

interface IGetCustomer {
    statusCode: number
    message: string
    data: Customer[] | any
}

export const customerAPI = createApi({
    reducerPath: 'customerAPI',
    baseQuery: baseQuery,
    tagTypes: ['Customers', 'CustomersId'],
    endpoints: builder => ({
        getCustomer: builder.query<IGetCustomer, void>({
            query: () => '/customer',
            providesTags: ['Customers'],
        }),
        getOneCustomer: builder.query({
            query: payload => `/customer/${payload}`,
            providesTags: ['CustomersId'],
        }),
        addCustomer: builder.mutation({
            query: payload => ({
                url: '/customer',
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['Customers', 'CustomersId'],
        }),
        updateCustomer: builder.mutation({
            query: ({ payload, id }) => ({
                url: `/customer/${id}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ['Customers', 'CustomersId'],
        }),
        deleteCustomer: builder.mutation({
            query: payload => ({
                url: `/customer/${payload}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Customers', 'CustomersId'],
        }),
    }),
})

export const {
    useGetCustomerQuery,
    useGetOneCustomerQuery,
    useAddCustomerMutation,
    useUpdateCustomerMutation,
    useDeleteCustomerMutation,
} = customerAPI
