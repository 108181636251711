import { createApi } from '@reduxjs/toolkit/query/react'
import Business from '../../models/Business'
import { baseQuery } from '../setupAPI'
//there too

interface IMyData {
    statusCode: number
    message: string
    data: {
        business_data: Business
    }
}

interface IGetBusiness {
    statusCode: number
    message: string
    data: Business[]
}

export const businessAPI = createApi({
    reducerPath: 'businessAPI',
    baseQuery: baseQuery,
    endpoints: builder => ({
        getMyData: builder.query<IMyData, void>({
            query: () => '/my_data',
        }),
        getBusiness: builder.query<IGetBusiness, void>({
            query: () => '/business',
        }),
        chooseBusiness: builder.mutation<{ data: {} }, { business_id: number }>({
            query: payload => ({
                url: '/choose_business',
                method: 'POST',
                body: payload,
            }),
        }),
    }),
})

export const { useGetMyDataQuery, useGetBusinessQuery, useChooseBusinessMutation } = businessAPI
