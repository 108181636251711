import { Link } from '@chakra-ui/react'
const NavLink = props => {
    const { action, label } = props
    return (
        <Link onClick={action} py={1} w={'full'} textTransform={'capitalize'}>
            {label}
        </Link>
    )
}

export default NavLink
