import {
    Box,
    Button,
    Flex,
    FormControl,
    FormHelperText,
    FormLabel,
    Heading,
    Input,
    useToast,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { RootState } from '../../app/store'
import {
    useAddCustomerMutation,
    useGetOneCustomerQuery,
    useUpdateCustomerMutation,
} from '../../features/customer/customerAPI'
import { reset, setCustomerData, setCustomerState } from '../../features/customer/customerSlice'
import { validateAddCustomer } from '../../validation/customer'

const AddCustomer: React.FC = () => {
    //state
    const { fullname, address, phone, email } = useSelector(
        (state: RootState) => state.customerReducer
    )
    const [errMessage, setErrMessage] = useState<any>(null)

    //hooks
    const { id } = useParams()
    const dispatch = useDispatch()
    const [addCustomer, { isLoading: isLoadingAdd }] = useAddCustomerMutation()
    const [updateCustomer, { isLoading: isLoadingUpdate }] = useUpdateCustomerMutation()

    const { data: dataCustomer } = useGetOneCustomerQuery(id ? id : null)
    const toast = useToast()
    const navigate = useNavigate()

    //handler
    const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
        setErrMessage(null)
        dispatch(setCustomerData({ name: e.currentTarget.name, value: e.currentTarget.value }))
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        let formData = { fullname, address, phone, email }
        let formValidation = validateAddCustomer(formData)
        if (formValidation.status === 'success') {
            let res
            if (id) {
                res = await updateCustomer({ payload: formData, id: id })
            } else {
                res = await addCustomer(formData)
            }
            if ('data' in res) {
                toast({
                    title: 'Save Customer Successfull.',
                    status: 'success',
                    colorScheme: 'blue',
                    duration: 3000,
                    isClosable: true,
                })
                navigate('/customer')
            }
        } else {
            setErrMessage(formValidation.errMsg)
        }
        dispatch(reset())
    }

    useEffect(() => {
        if (id && dataCustomer?.message === 'success' && dataCustomer) {
            dispatch(setCustomerState(dataCustomer.data[0]))
        } else {
            dispatch(reset())
        }
    }, [id, dataCustomer, dispatch])

    return (
        <Box bg={'gray.50'} minH={'100vh'}>
            <Flex
                px={5}
                py={[5, 10]}
                alignItems={['flex-start', 'center']}
                justifyContent={['space-between', 'space-between']}
                flexDir={['column', 'row']}
            >
                <Box px={0} mx={0}>
                    <Heading>{id ? 'Edit' : 'Add'} Customer</Heading>
                </Box>
                <Box mt={[5]} w={['full', 'auto']}></Box>
            </Flex>
            <Box p={3} px={5} my={5}>
                <Box bg={'white'} shadow={'md'} borderRadius={20} p={3}>
                    <form onSubmit={handleSubmit}>
                        <FormControl my={3}>
                            <FormLabel>Fullname</FormLabel>
                            <Input
                                type={'text'}
                                py={6}
                                name={'fullname'}
                                placeholder={'Fullname'}
                                onChange={handleChange}
                                errorBorderColor='red.300'
                                value={fullname}
                                isInvalid={errMessage?.fullname && true}
                            />
                            <FormHelperText color={'red.300'} mt={1}>
                                {errMessage?.fullname}
                            </FormHelperText>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Address</FormLabel>
                            <Input
                                type={'text'}
                                my={3}
                                py={6}
                                name={'address'}
                                placeholder={'Address'}
                                value={address}
                                onChange={handleChange}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Phone</FormLabel>
                            <Input
                                type={'text'}
                                my={3}
                                py={6}
                                name={'phone'}
                                placeholder={'Phone'}
                                value={phone}
                                onChange={handleChange}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Email</FormLabel>
                            <Input
                                type={'text'}
                                my={3}
                                py={6}
                                name={'email'}
                                placeholder={'Email'}
                                value={email}
                                onChange={handleChange}
                            />
                        </FormControl>
                        {id ? (
                            <Button type={'submit'} mr={3} isLoading={isLoadingUpdate}>
                                Save
                            </Button>
                        ) : (
                            <Button type={'submit'} mr={3} isLoading={isLoadingAdd}>
                                Save
                            </Button>
                        )}

                        <Button onClick={() => navigate(-1)}>Cancel</Button>
                    </form>
                </Box>
            </Box>
        </Box>
    )
}

export default AddCustomer
