import { createApi } from '@reduxjs/toolkit/query/react'
import Product from '../../models/Product'
import { baseURL, baseQuery } from '../setupAPI'
//there too
interface IGetProduct {
    statusCode: number
    message: string
    data: Product[] | any
}

export const productAPI = createApi({
    reducerPath: 'productAPI',
    baseQuery: baseQuery,
    tagTypes: ['Products', 'ProductId'],
    endpoints: builder => ({
        getProduct: builder.query<IGetProduct, void>({
            query: () => '/product',
            providesTags: ['Products'],
        }),
        getOneProduct: builder.query({
            query: payload => `/product/${payload}`,
            providesTags: ['ProductId'],
        }),
        addProduct: builder.mutation({
            query: payload => ({
                url: '/product',
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['Products', 'ProductId'],
        }),
        updateProduct: builder.mutation({
            query: ({ payload, id }) => ({
                url: `/product/${id}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ['Products', 'ProductId'],
        }),
        deleteProduct: builder.mutation({
            query: payload => ({
                url: `/product/${payload}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Products', 'ProductId'],
        }),
        fileUpload: builder.mutation({
            query: payload => ({
                url: '/file',
                method: 'POST',
                body: payload,
            }),
        }),
    }),
})

export const {
    useGetProductQuery,
    useGetOneProductQuery,
    useAddProductMutation,
    useUpdateProductMutation,
    useDeleteProductMutation,
    useFileUploadMutation,
} = productAPI
