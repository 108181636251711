import {
    Box,
    Button,
    Flex,
    FormControl,
    FormHelperText,
    FormLabel,
    Heading,
    Img,
    Input,
    useToast,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { RootState } from '../../app/store'
import {
    useAddProductMutation,
    useFileUploadMutation,
    useGetOneProductQuery,
    useUpdateProductMutation,
} from '../../features/product/productAPI'
import { setProductData, setProductState, reset } from '../../features/product/productSlice'
import { validateAddProduct } from '../../validation/product'

const AddProduct = () => {
    //state
    const { product_name, product_price, product_desc, product_photo } = useSelector(
        (state: RootState) => state.productReducer
    )
    const [errMessage, setErrMessage] = useState<any>(null)
    const [file, setFile] = useState<File | null>(null)
    const [fileSrc, setFileSrc] = useState<string | undefined>()

    //hooks
    const { id } = useParams()
    const dispatch = useDispatch()
    const [addProduct, { isLoading: isLoadingAdd }] = useAddProductMutation()
    const [updateProduct, { isLoading: isLoadingUpdate }] = useUpdateProductMutation()
    const [fileUpload, { isLoading: isLoadingFile }] = useFileUploadMutation()

    const { data: dataProduct } = useGetOneProductQuery(id ? id : null)
    const toast = useToast()
    const navigate = useNavigate()

    //handler
    const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
        setErrMessage(null)
        if (e.currentTarget.name === 'product_photo') {
            setFile(e!.currentTarget!.files![0])
            setFileSrc(URL.createObjectURL(e.currentTarget!.files![0]))
        }
        dispatch(setProductData({ name: e.currentTarget.name, value: e.currentTarget.value }))
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        interface IFormData {
            product_name: string
            product_price: number
            product_desc: string
            product_photo: string | null
        }
        let formData: IFormData = { product_name, product_price, product_desc, product_photo }
        if (file !== null) {
            let img = new FormData()
            img.append('file', file)
            console.log(img)
            const resImage = await fileUpload(img)
            console.log(resImage)
            if ('data' in resImage) {
                let imageLocation = resImage.data.data.file_location
                formData = { ...formData, product_photo: imageLocation }
            }
        } else {
            formData = { ...formData, product_photo }
        }

        let formValidation = validateAddProduct(formData)
        if (formValidation.status === 'success') {
            let res
            if (id) {
                res = await updateProduct({ payload: formData, id: id })
            } else {
                res = await addProduct(formData)
            }
            if ('data' in res) {
                toast({
                    title: 'Save Product Successfull.',
                    status: 'success',
                    colorScheme: 'blue',
                    duration: 3000,
                    isClosable: true,
                })
                navigate('/product')
            }
        } else {
            setErrMessage(formValidation.errMsg)
        }
    }

    useEffect(() => {
        if (id && dataProduct?.message === 'success' && dataProduct) {
            dispatch(setProductState(dataProduct.data[0]))
            setFileSrc(dataProduct.data[0].product_photo)
        } else {
            dispatch(reset())
        }
    }, [id, dataProduct, dispatch])

    return (
        <Box bg={'gray.50'} minH={'100vh'}>
            <Flex
                px={5}
                py={[5, 10]}
                alignItems={['flex-start', 'center']}
                justifyContent={['space-between', 'space-between']}
                flexDir={['column', 'row']}
            >
                <Box px={0} mx={0}>
                    <Heading>{id ? 'Edit' : 'Add'} Product</Heading>
                </Box>
                <Box mt={[5]} w={['full', 'auto']}></Box>
            </Flex>
            <Box p={3} px={5} my={5}>
                <Box bg={'white'} shadow={'md'} borderRadius={20} p={3}>
                    <form onSubmit={handleSubmit} encType={'multipart/form-data'}>
                        <FormControl my={3}>
                            <FormLabel>Product Image</FormLabel>
                            <Img src={fileSrc} width={'200px'} my={5} />
                            <Input
                                type={'file'}
                                name={'product_photo'}
                                placeholder={'Product Photo'}
                                onChange={handleChange}
                                errorBorderColor='red.300'
                            />
                        </FormControl>
                        <FormControl my={3}>
                            <FormLabel>Product Name</FormLabel>
                            <Input
                                type={'text'}
                                py={6}
                                name={'product_name'}
                                placeholder={'Product Name'}
                                onChange={handleChange}
                                errorBorderColor='red.300'
                                value={product_name}
                                isInvalid={errMessage?.product_name && true}
                            />
                            <FormHelperText color={'red.300'} mt={1}>
                                {errMessage?.product_name}
                            </FormHelperText>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Product Price</FormLabel>
                            <Input
                                type={'text'}
                                my={3}
                                py={6}
                                name={'product_price'}
                                placeholder={'Product Price'}
                                value={product_price}
                                onChange={handleChange}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Product Description</FormLabel>
                            <Input
                                type={'text'}
                                my={3}
                                py={6}
                                name={'product_desc'}
                                placeholder={'Product Description'}
                                value={product_desc}
                                onChange={handleChange}
                            />
                        </FormControl>

                        {id ? (
                            <Button
                                type={'submit'}
                                mr={3}
                                isLoading={isLoadingFile || isLoadingUpdate}
                            >
                                Save
                            </Button>
                        ) : (
                            <Button
                                type={'submit'}
                                mr={3}
                                isLoading={isLoadingFile || isLoadingAdd}
                            >
                                Save
                            </Button>
                        )}

                        <Button onClick={() => navigate(-1)}>Cancel</Button>
                    </form>
                </Box>
            </Box>
        </Box>
    )
}

export default AddProduct
