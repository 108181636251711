import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export const options = {
    maintainAspectRatio: false,
}

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July']

export const data = {
    labels,
    datasets: [
        {
            label: 'Pemasukan',
            data: [100, 200, 300, 400, 500, 600, 700],
            backgroundColor: '#2B6BB0',
        },
        {
            label: 'Pengeluaran',
            data: [300, 100, 200, 500, 600, 200, 300],
            backgroundColor: '#d81b60',
        },
    ],
}

const CardStatistic = () => {
    return <Bar options={options} data={data} width={'100%'} height={'100%'} />
}

export default CardStatistic
