import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { baseURL } from '../setupAPI'
//there too

export const authAPI = createApi({
    reducerPath: 'authAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: baseURL,
    }),
    endpoints: builder => ({
        login: builder.mutation({
            query: payload => ({
                url: '/login',
                method: 'POST',
                body: payload,
            }),
        }),
    }),
})

export const { useLoginMutation } = authAPI
