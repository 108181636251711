import {
    Box,
    Button,
    Flex,
    FormControl,
    FormHelperText,
    FormLabel,
    Heading,
    Input,
    useToast,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { RootState } from '../../app/store'
import {
    useAddPaymentMutation,
    useGetOnePaymentQuery,
    useUpdatePaymentMutation,
} from '../../features/payment/paymentAPI'
import { setPaymentData, setPaymentState, reset } from '../../features/payment/paymentSlice'
import { validateAddPayment } from '../../validation/payment'

const AddPayment: React.FC = () => {
    //state
    const { payment_name, payment_tipe } = useSelector((state: RootState) => state.paymentReducer)
    const [errMessage, setErrMessage] = useState<any>(null)

    //hooks
    const { id } = useParams()
    const dispatch = useDispatch()
    const [addPayment, { isLoading: isLoadingAdd }] = useAddPaymentMutation()
    const [updatePayment, { isLoading: isLoadingUpdate }] = useUpdatePaymentMutation()

    const { data: dataPayment } = useGetOnePaymentQuery(id ? id : null)
    const toast = useToast()
    const navigate = useNavigate()

    //handler
    const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
        setErrMessage(null)
        dispatch(setPaymentData({ name: e.currentTarget.name, value: e.currentTarget.value }))
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        let formData = { payment_name, payment_tipe }
        let formValidation = validateAddPayment(formData)
        if (formValidation.status === 'success') {
            let res
            if (id) {
                res = await updatePayment({ payload: formData, id: id })
            } else {
                res = await addPayment(formData)
            }
            if ('data' in res) {
                toast({
                    title: 'Save Payment Successfull.',
                    status: 'success',
                    colorScheme: 'blue',
                    duration: 3000,
                    isClosable: true,
                })
                navigate('/Payment')
            }
        } else {
            setErrMessage(formValidation.errMsg)
        }
        dispatch(reset())
    }

    useEffect(() => {
        if (id && dataPayment?.message === 'success' && dataPayment) {
            dispatch(setPaymentState(dataPayment.data[0]))
        } else {
            dispatch(reset())
        }
    }, [id, dataPayment, dispatch])

    return (
        <Box bg={'gray.50'} minH={'100vh'}>
            <Flex
                px={5}
                py={[5, 10]}
                alignItems={['flex-start', 'center']}
                justifyContent={['space-between', 'space-between']}
                flexDir={['column', 'row']}
            >
                <Box px={0} mx={0}>
                    <Heading>{id ? 'Edit' : 'Add'} Payment</Heading>
                </Box>
                <Box mt={[5]} w={['full', 'auto']}></Box>
            </Flex>
            <Box p={3} px={5} mt={5}>
                <Box bg={'white'} shadow={'md'} borderRadius={20} p={3}>
                    <form onSubmit={handleSubmit}>
                        <FormControl my={3}>
                            <FormLabel>Payment Name</FormLabel>
                            <Input
                                type={'text'}
                                py={6}
                                name={'payment_name'}
                                placeholder={'Payment Name'}
                                onChange={handleChange}
                                errorBorderColor='red.300'
                                value={payment_name}
                                isInvalid={errMessage?.payment_name && true}
                            />
                            <FormHelperText color={'red.300'} mt={1}>
                                {errMessage?.fullname}
                            </FormHelperText>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Payment Tipe</FormLabel>
                            <Input
                                type={'text'}
                                my={3}
                                py={6}
                                name={'payment_tipe'}
                                placeholder={'Payment Tipe'}
                                value={payment_tipe}
                                onChange={handleChange}
                            />
                        </FormControl>

                        {id ? (
                            <Button type={'submit'} mr={3} isLoading={isLoadingUpdate}>
                                Save
                            </Button>
                        ) : (
                            <Button type={'submit'} mr={3} isLoading={isLoadingAdd}>
                                Save
                            </Button>
                        )}

                        <Button onClick={() => navigate(-1)}>Cancel</Button>
                    </form>
                </Box>
            </Box>
        </Box>
    )
}

export default AddPayment
