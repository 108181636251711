import { Box, Button, Center, Flex, Heading, Spinner } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import CustomTable from '../../components/Table'
import { useDeleteCustomerMutation, useGetCustomerQuery } from '../../features/customer/customerAPI'
import CustomerM from '../../models/Customer'

const Customer: React.FC = () => {
    //state
    const columns = useMemo(
        () => [
            {
                Header: 'Full Name',
                accessor: 'fullname',
            },
            {
                Header: 'Address',
                accessor: 'address',
            },
            {
                Header: 'Phone',
                accessor: 'phone',
            },
            {
                Header: 'Email',
                accessor: 'email',
            },
        ],
        []
    )

    //hooks
    const navigate = useNavigate()
    const { data, isFetching } = useGetCustomerQuery()

    const [deleteCustomer] = useDeleteCustomerMutation()

    const openLink = (url: string) => navigate(url)

    //handler
    const handleEdit = (data: CustomerM) => {
        navigate(`/edit-customer/${data.customer_id}`)
    }

    const handleDelete = async (id: number) => {
        await deleteCustomer(id)
    }

    return (
        <Box bg={'gray.50'} minH={'100vh'}>
            <Flex
                px={5}
                py={[5, 10]}
                alignItems={['flex-start', 'center']}
                justifyContent={['space-between', 'space-between']}
                flexDir={['column', 'row']}
            >
                <Box px={0} mx={0}>
                    <Heading>Customer List</Heading>
                </Box>
                <Box mt={[5]} w={['full', 'auto']}>
                    <Button
                        bg={'blue.500'}
                        color={'white'}
                        _hover={{
                            background: 'blue.500',
                        }}
                        onClick={() => openLink('/add-customer')}
                    >
                        New Customer
                    </Button>
                </Box>
            </Flex>
            <Box p={3} px={5} my={5}>
                <Box bg={'white'} shadow={'md'} borderRadius={20} p={3} overflowX={'scroll'}>
                    {isFetching ? (
                        <Center py={12}>
                            <Spinner color={'blue.500'} />
                        </Center>
                    ) : data?.message === 'customer empty' || data?.data?.length === 0 ? (
                        <Center py={12}>Data Empty</Center>
                    ) : (
                        <CustomTable
                            id={'customer_id'}
                            columns={columns}
                            data={data?.data}
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                        />
                    )}
                </Box>
            </Box>
        </Box>
    )
}

export default Customer
