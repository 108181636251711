import {
    Box,
    Input,
    InputGroup,
    InputRightElement,
    ListItem,
    List,
    Spinner,
    Center,
    Flex,
    Button,
    Text,
    useToast,
} from '@chakra-ui/react'
import React from 'react'
import { ChevronLeft, ChevronRight, Search } from 'react-feather'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setUserData } from '../../features/auth/authSlice'
import {
    businessAPI,
    useChooseBusinessMutation,
    useGetBusinessQuery,
} from '../../features/business/businessAPI'
import { customerAPI } from '../../features/customer/customerAPI'
import { supplierAPI } from '../../features/supplier/supplierAPI'
import { paymentAPI } from '../../features/payment/paymentAPI'
import { productAPI } from '../../features/product/productAPI'

const ChooseBusiness: React.FC = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const toast = useToast()
    const { data, isFetching } = useGetBusinessQuery()

    const [chooseBusiness] = useChooseBusinessMutation()

    const handleChooseBusiness = async (id: number) => {
        try {
            const res = await chooseBusiness({ business_id: id })
            if ('data' in res) {
                dispatch(setUserData(res.data.data))
                dispatch(setUserData(res.data.data))
                dispatch(customerAPI.util.resetApiState())
                dispatch(supplierAPI.util.resetApiState())
                dispatch(paymentAPI.util.resetApiState())
                dispatch(productAPI.util.resetApiState())
                dispatch(businessAPI.util.resetApiState())
                navigate('/dashboard')
            }
        } catch (error: any) {
            toast({
                title: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        }
    }
    return (
        <Box
            w={'full'}
            minH={'100vh'}
            background={'gray.100'}
            display={'flex'}
            alignItems='center'
            justifyContent={'center'}
        >
            <Box background={'white'} w={['90%', '60%', '35%']} shadow={'md'} p={5}>
                <Text fontSize={'1.8rem'} fontWeight={'bold'}>
                    Choose Business
                </Text>
                <Text> Choose your business to proceed</Text>

                <InputGroup my={3}>
                    <Input
                        type={'text'}
                        my={3}
                        py={6}
                        name={'business_name'}
                        placeholder={'Business Name'}
                        bg={'gray.100'}
                    />
                    <InputRightElement
                        width='4rem'
                        justifyContent={'center'}
                        display='flex'
                        h={'100%'}
                    >
                        <Search />
                    </InputRightElement>
                </InputGroup>
                <Flex
                    alignItems={'center'}
                    justifyContent={'center'}
                    flexDir={'column'}
                    minH={'200px'}
                >
                    {isFetching ? (
                        <Center>
                            <Spinner color={'blue.500'} />
                        </Center>
                    ) : (
                        <>
                            <List w={'full'}>
                                {data?.data.map((list, index) => (
                                    <ListItem key={index} my={4} w={'full'}>
                                        <Button
                                            w={'full'}
                                            display={'flex'}
                                            justifyContent={'space-between'}
                                            textTransform={'capitalize'}
                                            rightIcon={<ChevronRight />}
                                            onClick={() => handleChooseBusiness(list.id)}
                                            _hover={{
                                                bg: 'blue.500',
                                                color: 'white',
                                            }}
                                        >
                                            {list.business_name}
                                        </Button>
                                    </ListItem>
                                ))}
                            </List>

                            <Box w={'full'} mt={5}>
                                <Button
                                    leftIcon={<ChevronLeft />}
                                    w={'full'}
                                    bg={'blue.600'}
                                    color={'white'}
                                    _hover={{
                                        bg: 'blue.500',
                                    }}
                                    onClick={() => navigate(-1)}
                                >
                                    Back
                                </Button>
                            </Box>
                        </>
                    )}
                </Flex>
            </Box>
        </Box>
    )
}

export default ChooseBusiness
