import { fetchBaseQuery } from '@reduxjs/toolkit/query'

let baseURL: string
if (process.env.NODE_ENV === 'production') {
    baseURL = process.env.REACT_APP_API_URL_PRODUCTION!
} else {
    baseURL = process.env.REACT_APP_API_URL_LOCAL!
}

const baseQuery = fetchBaseQuery({
    baseUrl: baseURL,
    prepareHeaders: (headers, { getState, endpoint }) => {
        const token = localStorage.getItem('token')
        if (token) {
            headers.set('Authorization', `Bearer ${token}`)
        }
        return headers
    },
})

export { baseQuery, baseURL }
