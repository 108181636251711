import { createApi } from '@reduxjs/toolkit/query/react'
import Payment from '../../models/Payment'
import { baseQuery } from '../setupAPI'

interface IGetPayment {
    statusCode: number
    message: string
    data: Payment[] | any
}

export const paymentAPI = createApi({
    reducerPath: 'paymentAPI',
    baseQuery: baseQuery,
    tagTypes: ['Payments', 'PaymentId'],
    endpoints: builder => ({
        getPayment: builder.query<IGetPayment, void>({
            query: () => '/payment',
            providesTags: ['Payments'],
        }),
        getOnePayment: builder.query({
            query: payload => `/payment/${payload}`,
            providesTags: ['PaymentId'],
        }),
        addPayment: builder.mutation({
            query: payload => ({
                url: '/payment',
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['Payments', 'PaymentId'],
        }),
        updatePayment: builder.mutation({
            query: ({ payload, id }) => ({
                url: `/payment/${id}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ['Payments', 'PaymentId'],
        }),
        deletePayment: builder.mutation({
            query: payload => ({
                url: `/payment/${payload}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Payments', 'PaymentId'],
        }),
    }),
})

export const {
    useGetPaymentQuery,
    useGetOnePaymentQuery,
    useAddPaymentMutation,
    useUpdatePaymentMutation,
    useDeletePaymentMutation,
} = paymentAPI
