import { Box, Button, Flex, Heading, HStack, IconButton, Text } from '@chakra-ui/react'
import { ChevronLeft } from 'react-feather'
import { useNavigate } from 'react-router-dom'

const AppBar = props => {
    //state
    const { title } = props

    //hooks
    const navigate = useNavigate()

    //handler

    return (
        <Flex
            id={'header'}
            w={'full'}
            bg={'blue.500'}
            color={'white'}
            p={3}
            justifyContent={'space-between'}
            shadow={'md'}
            zIndex={1}
            position={'fixed'}
            top={0}
        >
            <Flex alignItems={'center'} justifyContent={'space-between'} w={'full'}>
                <IconButton
                    icon={<ChevronLeft />}
                    bg={'transparent'}
                    fontWeight={'bold'}
                    _hover={{ bg: 'transparent' }}
                    onClick={() => navigate(-1)}
                />
                <Text fontWeight={'bold'} textTransform={'capitalize'}>
                    {title}
                </Text>
                <Text>&nbsp;</Text>
            </Flex>
        </Flex>
    )
}

export default AppBar
