import { Box, Button, Center, Flex, Heading, Spinner } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import CustomTable from '../../components/Table'
import { useDeleteProductMutation, useGetProductQuery } from '../../features/product/productAPI'
import ProductM from '../../models/Product'

const Product: React.FC = () => {
    //state
    const columns = useMemo(
        () => [
            {
                Header: 'Product Photo',
                accessor: 'product_photo',
                type: 'img',
            },
            {
                Header: 'Product Name',
                accessor: 'product_name',
            },
            {
                Header: 'Product Price',
                accessor: 'product_price',
            },
            {
                Header: 'Product Desc',
                accessor: 'product_desc',
            },
        ],
        []
    )

    //hooks
    const navigate = useNavigate()
    const { data, isFetching } = useGetProductQuery()
    const list = data?.data

    const [deleteProduct] = useDeleteProductMutation()

    const openLink = (url: string) => navigate(url)

    //handler
    const handleEdit = (data: ProductM) => {
        navigate(`/edit-product/${data.product_id}`)
    }

    const handleDelete = async (id: number) => {
        await deleteProduct(id)
    }

    return (
        <Box bg={'gray.50'} minH={'100vh'}>
            <Flex
                px={5}
                py={[5, 10]}
                alignItems={['flex-start', 'center']}
                justifyContent={['space-between', 'space-between']}
                flexDir={['column', 'row']}
            >
                <Box px={0} mx={0}>
                    <Heading>Product List</Heading>
                </Box>
                <Box mt={[5]} w={['full', 'auto']}>
                    <Button
                        bg={'blue.500'}
                        color={'white'}
                        onClick={() => openLink('/add-product')}
                        _hover={{
                            background: 'blue.500',
                        }}
                    >
                        New Product
                    </Button>
                </Box>
            </Flex>
            <Box p={3} px={5} my={5}>
                <Box bg={'white'} shadow={'md'} borderRadius={20} p={3}>
                    {isFetching ? (
                        <Center py={12}>
                            <Spinner color={'blue.500'} />
                        </Center>
                    ) : !list || list?.length === 0 ? (
                        <Center py={12}>Data Empty</Center>
                    ) : (
                        <CustomTable
                            id={'product_id'}
                            columns={columns}
                            data={list}
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                        />
                    )}
                </Box>
            </Box>
        </Box>
    )
}

export default Product
