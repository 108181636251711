import {
    Avatar,
    Box,
    Flex,
    Heading,
    HStack,
    Link,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
    Accordion,
    AccordionItem,
    AccordionIcon,
    AccordionPanel,
    AccordionButton,
    VStack,
    Img,
    useBoolean,
    Text,
} from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { Menu as MenuIcon } from 'react-feather'
import { useNavigate } from 'react-router-dom'
import aksaIcon from '../common/aksa-1.png'
import NavLink from './NavLink'

const Navbar = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [isSticky, setIsSticky] = useState(false)
    const btnRef = useRef()
    const navigate = useNavigate()
    const openLink = url => {
        navigate(url)
        onClose()
    }

    const windowScroll = e => {
        const scrollTop = window.scrollY
        scrollTop >= 20 ? setIsSticky(true) : setIsSticky(false)
    }

    useEffect(() => {
        window.addEventListener('scroll', windowScroll)
        return () => {
            window.removeEventListener('scroll', windowScroll)
        }
    })

    return (
        <>
            <Flex
                id={'header'}
                w={'full'}
                bg={'white'}
                color={'blue.500'}
                p={3}
                px={5}
                justifyContent={'space-between'}
                shadow={'md'}
                zIndex={1}
                position={isSticky ? 'fixed' : ''}
            >
                <HStack>
                    <Box>
                        <Img src={aksaIcon} w={'50px'} />
                    </Box>
                    <Box>
                        <Heading textAlign={'center'} color={'gray.500'} mr={5} size={'lg'}>
                            Aksa
                            <Text display={'inline'} color={'blue.500'}>
                                POS
                            </Text>
                        </Heading>
                    </Box>
                    <HStack spacing={12} display={['none', 'flex', 'flex', 'flex']}>
                        <Link onClick={() => openLink('/dashboard')}>Dashboard</Link>
                        <Link>
                            <Menu>
                                <MenuButton>Data Master</MenuButton>
                                <MenuList>
                                    <MenuItem onClick={() => openLink('/customer')}>
                                        Customers
                                    </MenuItem>
                                    <MenuItem onClick={() => openLink('/product')}>
                                        Products
                                    </MenuItem>
                                    <MenuItem onClick={() => openLink('/supplier')}>
                                        Suppliers
                                    </MenuItem>
                                    <MenuItem onClick={() => openLink('/payment')}>
                                        Payments
                                    </MenuItem>
                                </MenuList>
                            </Menu>
                        </Link>
                        <Link>
                            <Menu>
                                <MenuButton>Transaction</MenuButton>
                                <MenuList>
                                    <MenuItem onClick={() => openLink('/pos')}>POS</MenuItem>
                                    <MenuItem>List Transaksi Penjualan</MenuItem>
                                    <MenuItem>List Transaksi Pembelian</MenuItem>
                                </MenuList>
                            </Menu>
                        </Link>
                        <Link>
                            <Menu>
                                <MenuButton>Report</MenuButton>
                                <MenuList>
                                    <MenuItem>Laporan Penjualan</MenuItem>
                                    <MenuItem>Laporan Pembelian</MenuItem>
                                </MenuList>
                            </Menu>
                        </Link>
                    </HStack>
                </HStack>

                <HStack display={['none', 'flex']}>
                    <Avatar />
                </HStack>
                <HStack display={['flex', 'none']}>
                    <MenuIcon ref={btnRef} onClick={onOpen} />
                </HStack>
            </Flex>

            <Drawer isOpen={isOpen} placement='left' onClose={onClose} finalFocusRef={btnRef}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>Menu</DrawerHeader>

                    <DrawerBody>
                        <Accordion allowToggle>
                            <AccordionItem>
                                <AccordionButton onClick={() => openLink('/dashboard')}>
                                    <Box>Dashboard</Box>
                                </AccordionButton>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionButton>
                                    <Box flex='1' textAlign='left'>
                                        Data Master
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel>
                                    <VStack alignItems='flex-start'>
                                        <NavLink
                                            label={'customers'}
                                            action={() => openLink('/customer')}
                                        />
                                        <NavLink
                                            label={'products'}
                                            action={() => openLink('/product')}
                                        />
                                        <NavLink
                                            label={'suppliers'}
                                            action={() => openLink('/supplier')}
                                        />
                                        <NavLink
                                            label={'payments'}
                                            action={() => openLink('/payment')}
                                        />
                                    </VStack>
                                </AccordionPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionButton>
                                    <Box flex='1' textAlign='left'>
                                        Transaction
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel>
                                    <VStack alignItems='flex-start'>
                                        <NavLink label={'pos'} action={() => openLink('/pos')} />
                                        <NavLink
                                            label={'list transaksi penjualan'}
                                            action={() => openLink('/pos')}
                                        />
                                        <NavLink
                                            label={'list transaksi pembelian'}
                                            action={() => openLink('/pos')}
                                        />
                                    </VStack>
                                </AccordionPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionButton>
                                    <Box flex='1' textAlign='left'>
                                        Report
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel>
                                    <VStack alignItems='flex-start'>
                                        <NavLink
                                            label={'laporan penjualan'}
                                            action={() => openLink('/pos')}
                                        />
                                        <NavLink
                                            label={'laporan pembelian'}
                                            action={() => openLink('/pos')}
                                        />
                                    </VStack>
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default Navbar
