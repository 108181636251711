import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: any = {
    product_name: '',
    product_price: '',
    product_desc: '',
    product_photo: '',
}

const productSlice = createSlice({
    name: 'productSlice',
    initialState,
    reducers: {
        setProductData(state, action: PayloadAction<{ name: string; value: string }>) {
            state[action.payload.name] = action.payload.value
        },
        setProductState(state, action) {
            state.product_name = action.payload.product_name
            state.product_price = action.payload.product_price
            state.product_desc = action.payload.product_desc
            state.product_photo = action.payload.product_photo
        },
        reset(state, action: PayloadAction<void>) {
            state.product_name = ''
            state.product_price = ''
            state.product_desc = ''
            state.product_photo = ''
        },
    },
})

export const { setProductData, setProductState, reset } = productSlice.actions
export default productSlice
