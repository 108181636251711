import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import Payment from '../../models/Payment'

const initialState: any = {
    payment_name: '',
    payment_tipe: '',
    payment_options: [],
}

const paymentSlice = createSlice({
    name: 'paymentSlice',
    initialState,
    reducers: {
        setPaymentData(state, action: PayloadAction<{ name: string; value: string }>) {
            state[action.payload.name] = action.payload.value
        },
        setPaymentState(state, action) {
            state.payment_name = action.payload.payment_name
            state.payment_tipe = action.payload.payment_tipe
        },
        setPaymentOptions(state, action) {
            let data = action.payload
            interface Affected {
                id: number
                value: number
                label: string
            }
            let affected: Affected[] = []
            data.forEach((row: Payment, index: number) => {
                affected.push({
                    id: row.payment_id,
                    value: row.payment_id,
                    label: row.payment_name,
                })
            })
            return {
                ...state,
                payment_options: affected,
            }
        },
        reset(state, action: PayloadAction<void>) {
            state.payment_name = ''
            state.payment_tipe = ''
        },
    },
})

export const { setPaymentData, setPaymentState, setPaymentOptions, reset } = paymentSlice.actions
export default paymentSlice
