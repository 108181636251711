import { createApi } from '@reduxjs/toolkit/query/react'
import Supplier from '../../models/Supplier'
import { baseQuery } from '../setupAPI'

interface IGetSupplier {
    statusCode: number
    message: string
    data: Supplier[] | any
}

export const supplierAPI = createApi({
    reducerPath: 'supplierAPI',
    baseQuery: baseQuery,
    tagTypes: ['Suppliers', 'SupplierId'],
    endpoints: builder => ({
        getSupplier: builder.query<IGetSupplier, void>({
            query: () => '/supplier',
            providesTags: ['Suppliers'],
        }),
        getOneSupplier: builder.query({
            query: payload => `/supplier/${payload}`,
            providesTags: ['SupplierId'],
        }),
        addSupplier: builder.mutation({
            query: payload => ({
                url: '/supplier',
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['Suppliers', 'SupplierId'],
        }),
        updateSupplier: builder.mutation({
            query: ({ payload, id }) => ({
                url: `/supplier/${id}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ['Suppliers', 'SupplierId'],
        }),
        deleteSupplier: builder.mutation({
            query: payload => ({
                url: `/supplier/${payload}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Suppliers', 'SupplierId'],
        }),
    }),
})

export const {
    useGetSupplierQuery,
    useGetOneSupplierQuery,
    useAddSupplierMutation,
    useUpdateSupplierMutation,
    useDeleteSupplierMutation,
} = supplierAPI
