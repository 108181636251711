import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

const PrivateRoute = () => {
    let token = localStorage.getItem('token')
    if (token) {
        return <Outlet />
    } else {
        return <Navigate to='/' />
    }
}

export default PrivateRoute
