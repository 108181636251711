import { Box, Button, Flex, Heading, HStack, SimpleGrid, Text } from '@chakra-ui/react'
import { RefreshCw, ShoppingBag, TrendingDown, TrendingUp, User } from 'react-feather'
import { useNavigate } from 'react-router-dom'
import { useGetMyDataQuery } from '../../features/business/businessAPI'
import CardStatistic from './components/CardStatistic'
import CardTotal from './components/CardTotal'

const Dashboard = () => {
    //state

    //hooks
    const navigate = useNavigate()
    const openLink = (url: string) => navigate(url)
    const { data: myData } = useGetMyDataQuery()

    //handler

    return (
        <Box bg={'gray.50'} minH={'100vh'}>
            <Flex
                px={5}
                py={[5, 10]}
                alignItems={['flex-start', 'center']}
                justifyContent={['space-between', 'space-between']}
                flexDir={['column', 'row']}
            >
                <Box px={0} mx={0}>
                    <Heading>Welcome, Admin</Heading>
                    <HStack mt={2}>
                        <Text size={'sm'}>Choosed Business : </Text>
                        <Text textTransform={'capitalize'} display={'inline'}>
                            {myData?.data?.business_data?.business_name}
                        </Text>
                        <Button
                            size={'small'}
                            bg={'blue.500'}
                            color={'white'}
                            px={2}
                            py={1}
                            leftIcon={<RefreshCw width={'20px'} />}
                            fontSize={'0.7rem'}
                            onClick={() => openLink('/choose-business')}
                        >
                            Switch
                        </Button>
                    </HStack>
                </Box>
                <Box mt={[5]} overflowX={['scroll']} w={['full', 'auto']}>
                    <HStack>
                        <Button>Day</Button>
                        <Button>Week</Button>
                        <Button>Month</Button>
                        <Button>Year</Button>
                        <Button>Date Range</Button>
                    </HStack>
                </Box>
            </Flex>
            <Box p={3} px={5} mt={5}>
                <SimpleGrid columns={[1, 2, 2, 4]} spacing={8} color={'white'}>
                    <CardTotal
                        bgColor={'green.500'}
                        title={'Revenue'}
                        icon={TrendingUp}
                        data={10}
                    />
                    <CardTotal
                        bgColor={'yellow.500'}
                        title={'Expense'}
                        icon={TrendingDown}
                        data={10}
                    />
                    <CardTotal bgColor={'red.500'} title={'Business'} icon={User} data={10} />
                    <CardTotal
                        bgColor={'purple.500'}
                        title={'Transaction'}
                        icon={ShoppingBag}
                        data={10}
                    />
                </SimpleGrid>
            </Box>
            <Flex px={5} w={'full'}>
                <Box
                    p={3}
                    px={5}
                    mt={5}
                    height={['300px', '500px']}
                    position={'relative'}
                    bg={'white'}
                    shadow={'md'}
                    w={'full'}
                    borderRadius={20}
                >
                    <CardStatistic />
                </Box>
            </Flex>
        </Box>
    )
}

export default Dashboard
