import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: any = {
    customer_name: '',
    payment_id: '',
    subtotal: 0,
    discount: 0,
    total: 0,
    bayar: 0,
    kembali: 0,
    status: 0,
    items: [],
}

const posSlice = createSlice({
    name: 'posSlice',
    initialState,
    reducers: {
        setState(state, action) {
            return {
                ...state,
                [action.payload.name]: action.payload.value,
            }
        },
        addItem(state, action) {
            let item = {
                index: action.payload.index,
                items_id: action.payload.product_id,
                items_name: action.payload.product_name,
                price: action.payload.product_price,
                qty: 1,
            }
            state.items = [...state.items, item]
            let total = 0
            for (let key in state.items) {
                total += state.items[key].price * state.items[key].qty
            }
            state.total = total
        },
        addQty(state, action) {
            state.items[action.payload].qty = state.items[action.payload].qty + 1
            let total = 0
            for (let key in state.items) {
                total += state.items[key].price * state.items[key].qty
            }
            state.total = total
        },
        removeQty(state, action) {
            if (state.items[action.payload].qty > 1) {
                state.items[action.payload].qty = state.items[action.payload].qty - 1
            }
            let total = 0
            for (let key in state.items) {
                total += state.items[key].price * state.items[key].qty
            }
            state.total = total
        },
        removeItem(state, action) {
            state.items.splice(action.payload, 1)
            let total = 0
            for (let key in state.items) {
                total += state.items[key].price * state.items[key].qty
            }
            state.total = total
        },
        reset(state, action: PayloadAction<void>) {
            state.customer_name = ''
            state.payment_id = ''
            state.subtotal = 0
            state.discount = 0
            state.total = 0
            state.bayar = 0
            state.kembali = 0
            state.status = 0
            state.items = []
        },
    },
})

export const { setState, addItem, addQty, removeQty, removeItem, reset } = posSlice.actions
export default posSlice
