import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: any = {
    supplier_name: '',
    supplier_address: '',
    supplier_phone: '',
}

const supplierSlice = createSlice({
    name: 'supplierSlice',
    initialState,
    reducers: {
        setSupplierData(state, action) {
            state[action.payload.name] = action.payload.value
        },
        setSupplierState(state, action) {
            state.supplier_name = action.payload.supplier_name
            state.supplier_address = action.payload.supplier_address
            state.supplier_phone = action.payload.supplier_phone
        },
        reset(state, action: PayloadAction<void>) {
            state.supplier_name = ''
            state.supplier_address = ''
            state.supplier_phone = ''
        },
    },
})

export const { setSupplierData, setSupplierState, reset } = supplierSlice.actions
export default supplierSlice
