import { Box, Text } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Select from 'react-select'
import { useGetPaymentQuery } from '../../features/payment/paymentAPI'
import { setPaymentOptions } from '../../features/payment/paymentSlice'
import { useGetOneTransactionQuery } from '../../features/transaction/posAPI'
import { setState } from '../../features/transaction/posSlice'

const PosPayment = () => {
    //state
    const { payment_options } = useSelector(state => state.paymentReducer)
    const data = useSelector(state => state.posReducer)

    //hooks
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { data: dataPayment } = useGetPaymentQuery()

    //handler
    const handleChange = e => {
        dispatch(setState({ name: 'payment_id', value: e.value }))
    }

    useEffect(() => {
        if (dataPayment) {
            dispatch(setPaymentOptions(dataPayment?.data))
        }
    }, [dataPayment])

    useEffect(() => {
        console.log(dataPayment)
        const unloadCallback = event => {
            event.preventDefault()
            event.returnValue = ''
            return ''
        }

        window.addEventListener('beforeunload', unloadCallback)

        if (data.total === 0) {
            navigate('/pos')
        }
        return () => window.removeEventListener('beforeunload', unloadCallback)
    }, [data])

    return (
        <Box
            w={'full'}
            minH={'100vh'}
            background={'gray.100'}
            display={'flex'}
            alignItems='center'
            justifyContent={'center'}
        >
            <Box background={'white'} w={['90%', '60%', '35%']} shadow={'md'} p={5}>
                <Text fontSize={'1.8rem'} fontWeight={'bold'} textAlign={'center'}>
                    Total : {data?.total}
                </Text>
                <Text textAlign={'center'}> Choose payment method to proceed</Text>
                <Select
                    name='payment_id'
                    placeholder='Select payment method'
                    options={payment_options}
                    onChange={handleChange}
                />
            </Box>
        </Box>
    )
}

export default PosPayment
