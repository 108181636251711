const validateAddCustomer = (state: any) => {
    let errMsg: any = {}
    if (state.fullname.trim() === '') {
        errMsg.fullname = 'Fullname cannot be empty'
    }

    return {
        status: Object.keys(errMsg).length === 0 ? 'success' : 'error',
        errMsg,
    }
}

export { validateAddCustomer }
