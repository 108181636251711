import {
    Box,
    Button,
    Center,
    Flex,
    IconButton,
    Input,
    InputGroup,
    InputRightElement,
    SimpleGrid,
    Spinner,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { Minus, Plus, RefreshCw, Search, Trash } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { useGetProductQuery } from '../../features/product/productAPI'
import {
    addItem,
    addQty,
    removeItem,
    removeQty,
    reset,
    setState,
} from '../../features/transaction/posSlice'
import Select, { SingleValue } from 'react-select'
import { useGetCustomerQuery } from '../../features/customer/customerAPI'
import { setCustomerOptions } from '../../features/customer/customerSlice'
import { useAddTransactionMutation } from '../../features/transaction/posAPI'
import { useNavigate } from 'react-router-dom'
import { RootState } from '../../app/store'
import Item from '../../models/Item'
import Product from '../../models/Product'

const Pos: React.FC = () => {
    //state
    const { customer_options } = useSelector((state: RootState) => state.customerReducer)
    const { customer_name, payment_id, subtotal, discount, total, bayar, kembali, status, items } =
        useSelector((state: RootState) => state.posReducer)
    const [openPayment, setOpenPayment] = useState(false)

    //hooks
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { data, isFetching, isLoading } = useGetProductQuery()
    const list = data?.data

    const { data: dataCustomers, isFetching: isFetchingCustomer } = useGetCustomerQuery()

    const [addTransaction] = useAddTransactionMutation()

    //handler
    const handleChange = (newValue: SingleValue<React.FormEvent<HTMLInputElement>>) => {
        dispatch(setState({ name: 'customer_name', value: newValue }))
    }

    const handleAddItem = (item: Product, index: number) => {
        dispatch(addItem({ ...item, index }))
    }

    const handleAddQty = (index: number) => {
        dispatch(addQty(index))
    }

    const handleRemoveQtyQty = (index: number) => {
        dispatch(removeQty(index))
    }

    const handleRemoveItem = (index: number) => {
        dispatch(removeItem(index))
    }

    const handleRemoveAllItem = () => {
        dispatch(reset())
    }

    const saveTransaction = async () => {
        let formData = {
            customer_name,
            payment_id,
            subtotal,
            discount,
            total,
            bayar,
            kembali,
            status,
            items,
        }
        const res = await addTransaction(formData)
        return res
    }

    const handleHold = async () => {
        await saveTransaction()
    }

    const handlePay = async () => {
        navigate(`/pos/payment`)
    }

    useEffect(() => {
        if (dataCustomers?.data) {
            dispatch(setCustomerOptions(dataCustomers.data))
        }
    }, [dataCustomers, dispatch])

    return (
        <Box bg={'blue.50'} minH={'100vh'}>
            <Flex
                pt={5}
                alignItems={['flex-start']}
                justifyContent={['space-between', 'space-between']}
                flexDir={['column-reverse', 'row']}
                mb={3}
                px={[0, 3]}
            >
                <Box w={['100%', '40%']} px={3}>
                    <Box my={3}>
                        <Select
                            name='customer_name'
                            placeholder='Select customer name'
                            options={customer_options}
                            onChange={handleChange}
                        />
                    </Box>
                    <Box bg={'white'} p={3} h={'60vh'} overflowY={'scroll'}>
                        <TableContainer>
                            <Table variant='simple'>
                                <Thead position={'sticky'}>
                                    <Tr>
                                        <Th>#</Th>
                                        <Th>Name</Th>
                                        <Th>Qty</Th>
                                        <Th isNumeric>Total</Th>
                                    </Tr>
                                </Thead>
                                <Tbody height={'60vh'}>
                                    {items &&
                                        items.map((item: Item, index: number) => (
                                            <Tr key={index}>
                                                <Td w={'50px'} p={0} m={0} textAlign={'center'}>
                                                    <Button
                                                        m={0}
                                                        size={'sm'}
                                                        onClick={() => handleRemoveItem(index)}
                                                    >
                                                        <Trash />
                                                    </Button>
                                                </Td>
                                                <Td>{item.product_name}</Td>
                                                <Td w={'100px'}>
                                                    <InputGroup
                                                        display={'flex'}
                                                        alignItems={'center'}
                                                    >
                                                        <Button
                                                            size={'sm'}
                                                            onClick={() => handleAddQty(index)}
                                                        >
                                                            <Plus />
                                                        </Button>
                                                        <Text mx={5}>{item.qty}</Text>
                                                        <Button
                                                            size={'sm'}
                                                            onClick={() =>
                                                                handleRemoveQtyQty(index)
                                                            }
                                                        >
                                                            <Minus />
                                                        </Button>
                                                    </InputGroup>
                                                </Td>
                                                <Td textAlign={'right'}>{item.price * item.qty}</Td>
                                            </Tr>
                                        ))}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </Box>
                    <Flex
                        px={3}
                        py={3}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        bg={'gray.100'}
                    >
                        <Box>
                            <Button
                                mr={[2, 5]}
                                bg={'yellow.500'}
                                _hover={{ bg: 'yellow.600' }}
                                color={'white'}
                            >
                                Hold
                            </Button>
                            <Button
                                mr={[2, 5]}
                                bg={'teal.500'}
                                _hover={{ bg: 'teal.600' }}
                                color={'white'}
                                onClick={handlePay}
                            >
                                Pay
                            </Button>
                        </Box>
                        <Box textAlign={'right'} fontSize={['1.2rem', '2rem']}>
                            Rp. {total}
                        </Box>
                    </Flex>
                </Box>
                <Box w={['100%', '60%']} px={3}>
                    <Flex my={3} alignItems={'center'}>
                        <InputGroup mr={3}>
                            <Input
                                type={'text'}
                                name={'product_name'}
                                placeholder={'Product Name'}
                                bg={'white'}
                            />
                            <InputRightElement
                                width='4rem'
                                justifyContent={'center'}
                                display='flex'
                                h={'100%'}
                            >
                                <Search />
                            </InputRightElement>
                        </InputGroup>
                        <Button
                            bg={'red.500'}
                            _hover={{ bg: 'red.600' }}
                            color={'white'}
                            onClick={handleRemoveAllItem}
                            leftIcon={<RefreshCw width={'20px'} />}
                        >
                            Reset
                        </Button>
                    </Flex>
                    <Box bg={'white'} p={3} h={'60vh'} position={'relative'} overflowY={'scroll'}>
                        {isFetching ? (
                            <Center h={'100%'}>
                                <Spinner color={'blue.500'} />
                            </Center>
                        ) : !list || list?.length === 0 ? (
                            <Center py={12}>Data Empty</Center>
                        ) : (
                            <SimpleGrid columns={[1, 4]}>
                                {list.map((item: Product, index: number) => (
                                    <Box
                                        key={index}
                                        p={3}
                                        onClick={() => handleAddItem(item, index)}
                                    >
                                        <Box
                                            w={'100%'}
                                            height={['200px', '100px']}
                                            bgImage={item.product_photo}
                                            bgSize={'cover'}
                                            bgPosition={'center'}
                                            p={5}
                                        ></Box>
                                        <Box textAlign={'center'}>
                                            <Text>{item.product_name}</Text>
                                            <Text>{item.product_price}</Text>
                                        </Box>
                                    </Box>
                                ))}
                            </SimpleGrid>
                        )}
                    </Box>
                </Box>
            </Flex>
        </Box>
    )
}

export default Pos
