import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { authAPI } from '../features/auth/authAPI'
import authReducer from '../features/auth/authSlice'
import { businessAPI } from '../features/business/businessAPI'
import { customerAPI } from '../features/customer/customerAPI'
import customerReducer from '../features/customer/customerSlice'
import { paymentAPI } from '../features/payment/paymentAPI'
import { supplierAPI } from '../features/supplier/supplierAPI'
import supplierReducer from '../features/supplier/supplierSlice'
import paymentReducer from '../features/payment/paymentSlice'
import { productAPI } from '../features/product/productAPI'
import productReducer from '../features/product/productSlice'
import posReducer from '../features/transaction/posSlice'
import { posAPI } from '../features/transaction/posAPI'

export const store = configureStore({
    reducer: {
        [authAPI.reducerPath]: authAPI.reducer,
        [businessAPI.reducerPath]: businessAPI.reducer,
        [customerAPI.reducerPath]: customerAPI.reducer,
        [supplierAPI.reducerPath]: supplierAPI.reducer,
        [paymentAPI.reducerPath]: paymentAPI.reducer,
        [productAPI.reducerPath]: productAPI.reducer,
        [posAPI.reducerPath]: posAPI.reducer,
        authReducer: authReducer.reducer,
        customerReducer: customerReducer.reducer,
        supplierReducer: supplierReducer.reducer,
        paymentReducer: paymentReducer.reducer,
        productReducer: productReducer.reducer,
        posReducer: posReducer.reducer,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(
            authAPI.middleware,
            businessAPI.middleware,
            customerAPI.middleware,
            supplierAPI.middleware,
            paymentAPI.middleware,
            productAPI.middleware,
            posAPI.middleware
        ),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
setupListeners(store.dispatch)
