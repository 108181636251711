import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: any = {
    email: '',
    password: '',
}

const authSlice = createSlice({
    name: 'authSlice',
    initialState,
    reducers: {
        loginAction: (state, action: PayloadAction<{ name: string; value: string }>) => {
            state[action.payload.name] = action.payload.value
        },
        setUserData(state, action) {
            localStorage.setItem('token', action.payload.token)
        },
    },
})

export const { loginAction, setUserData } = authSlice.actions
export default authSlice
