import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Login from './pages/Auth/Login'
import PrivateRoute from './components/PrivateRoute'
import Dashboard from './pages/Dashboard/Dashboard'
import Container from './components/Container'
import Customer from './pages/Customer/Customer'
import ChooseBusiness from './pages/Business/ChooseBusiness'
import AddCustomer from './pages/Customer/AddCustomer'
import Supplier from './pages/Supplier/Supplier'
import AddSupplier from './pages/Supplier/AddSupplier'
import Payment from './pages/Payment/Payment'
import AddPayment from './pages/Payment/AddPayment'
import Product from './pages/Product/Product'
import AddProduct from './pages/Product/AddProduct'
import Pos from './pages/Transaction/Pos'
import PosPayment from './pages/Transaction/Payment'
import AppBar from './components/AppBar'

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Login />} />
                <Route element={<PrivateRoute />}>
                    <Route path='/choose-business' element={<ChooseBusiness />} />
                    <Route
                        path='/dashboard'
                        element={
                            <Container>
                                <Dashboard />
                            </Container>
                        }
                    />
                    <Route
                        path='/customer'
                        element={
                            <Container>
                                <Customer />
                            </Container>
                        }
                    />
                    <Route
                        path='/add-customer'
                        element={
                            <Container>
                                <AddCustomer />
                            </Container>
                        }
                    />
                    <Route
                        path='/edit-customer/:id'
                        element={
                            <Container>
                                <AddCustomer />
                            </Container>
                        }
                    />
                    <Route
                        path='/supplier'
                        element={
                            <Container>
                                <Supplier />
                            </Container>
                        }
                    />
                    <Route
                        path='/add-supplier'
                        element={
                            <Container>
                                <AddSupplier />
                            </Container>
                        }
                    />
                    <Route
                        path='/edit-supplier/:id'
                        element={
                            <Container>
                                <AddSupplier />
                            </Container>
                        }
                    />
                    <Route
                        path='/payment'
                        element={
                            <Container>
                                <Payment />
                            </Container>
                        }
                    />
                    <Route
                        path='/add-payment'
                        element={
                            <Container>
                                <AddPayment />
                            </Container>
                        }
                    />
                    <Route
                        path='/edit-payment/:id'
                        element={
                            <Container>
                                <AddPayment />
                            </Container>
                        }
                    />
                    <Route
                        path='/product'
                        element={
                            <Container>
                                <Product />
                            </Container>
                        }
                    />
                    <Route
                        path='/add-product'
                        element={
                            <Container>
                                <AddProduct />
                            </Container>
                        }
                    />
                    <Route
                        path='/edit-product/:id'
                        element={
                            <Container>
                                <AddProduct />
                            </Container>
                        }
                    />
                    <Route
                        path='/pos'
                        element={
                            <Container>
                                <Pos />
                            </Container>
                        }
                    />
                    <Route
                        path='/pos/payment'
                        element={
                            <>
                                <AppBar title={'payment'} />
                                <PosPayment />
                            </>
                        }
                    />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default App
