import { Box, Button, Center, Flex, Heading, Spinner } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import CustomTable from '../../components/Table'
import { useDeleteSupplierMutation, useGetSupplierQuery } from '../../features/supplier/supplierAPI'
import SupplierM from '../../models/Supplier'

const Supplier: React.FC = () => {
    //state
    const columns = useMemo(
        () => [
            {
                Header: 'Supplier Name',
                accessor: 'supplier_name',
            },
            {
                Header: 'Supplier Address',
                accessor: 'supplier_address',
            },
            {
                Header: 'Supplier Phone',
                accessor: 'supplier_phone',
            },
        ],
        []
    )

    //hooks
    const navigate = useNavigate()
    const { data, isFetching } = useGetSupplierQuery()
    const list = data?.data

    const [deleteSupplier] = useDeleteSupplierMutation()

    const openLink = (url: string) => navigate(url)

    //handler
    const handleEdit = (data: SupplierM) => {
        navigate(`/edit-supplier/${data.supplier_id}`)
    }

    const handleDelete = async (id: number) => {
        await deleteSupplier(id)
    }

    return (
        <Box bg={'gray.50'} minH={'100vh'}>
            <Flex
                px={5}
                py={[5, 10]}
                alignItems={['flex-start', 'center']}
                justifyContent={['space-between', 'space-between']}
                flexDir={['column', 'row']}
            >
                <Box px={0} mx={0}>
                    <Heading>Supplier List</Heading>
                </Box>
                <Box mt={[5]} w={['full', 'auto']}>
                    <Button
                        bg={'blue.500'}
                        color={'white'}
                        onClick={() => openLink('/add-supplier')}
                        _hover={{
                            background: 'blue.500',
                        }}
                    >
                        New Supplier
                    </Button>
                </Box>
            </Flex>
            <Box p={3} px={5} mt={5}>
                <Box bg={'white'} shadow={'md'} borderRadius={20} p={3} overflowX={'scroll'}>
                    {isFetching ? (
                        <Center py={12}>
                            <Spinner color={'blue.500'} />
                        </Center>
                    ) : !list || list?.length === 0 ? (
                        <Center py={12}>Data Empty</Center>
                    ) : (
                        <CustomTable
                            id={'supplier_id'}
                            columns={columns}
                            data={list}
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                        />
                    )}
                </Box>
            </Box>
        </Box>
    )
}

export default Supplier
