import { Box, Button, Center, Flex, Heading, Spinner } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import CustomTable from '../../components/Table'
import { useDeletePaymentMutation, useGetPaymentQuery } from '../../features/payment/paymentAPI'
import PaymentM from '../../models/Payment'

const Payment: React.FC = () => {
    //state
    const columns = useMemo(
        () => [
            {
                Header: 'Payment Name',
                accessor: 'payment_name',
            },
            {
                Header: 'Payment Tipe',
                accessor: 'payment_tipe',
            },
        ],
        []
    )

    //hooks
    const navigate = useNavigate()
    const { data, isFetching } = useGetPaymentQuery()
    const list = data?.data

    const [deletePayment] = useDeletePaymentMutation()

    const openLink = (url: string) => navigate(url)

    //handler
    const handleEdit = (data: PaymentM) => {
        navigate(`/edit-Payment/${data.payment_id}`)
    }

    const handleDelete = async (id: number) => {
        await deletePayment(id)
    }

    return (
        <Box bg={'gray.50'} minH={'100vh'}>
            <Flex
                px={5}
                py={[5, 10]}
                alignItems={['flex-start', 'center']}
                justifyContent={['space-between', 'space-between']}
                flexDir={['column', 'row']}
            >
                <Box px={0} mx={0}>
                    <Heading>Payment List</Heading>
                </Box>
                <Box mt={[5]} w={['full', 'auto']}>
                    <Button
                        bg={'blue.500'}
                        color={'white'}
                        onClick={() => openLink('/add-Payment')}
                        _hover={{
                            background: 'blue.500',
                        }}
                    >
                        New Payment
                    </Button>
                </Box>
            </Flex>
            <Box p={3} px={5} mt={5}>
                <Box bg={'white'} shadow={'md'} borderRadius={20} p={3} overflowX={'scroll'}>
                    {isFetching ? (
                        <Center py={12}>
                            <Spinner color={'blue.500'} />
                        </Center>
                    ) : !list || list?.length === 0 ? (
                        <Center py={12}>Data Empty</Center>
                    ) : (
                        <CustomTable
                            id={'payment_id'}
                            columns={columns}
                            data={list}
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                        />
                    )}
                </Box>
            </Box>
        </Box>
    )
}

export default Payment
